import React from "react"
import SEO from "../components/seo"

class Pricing extends React.Component {
  render() {
    return (
      <div id="pricing" className="pricing-area gray-bg pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 offset-lg-2 offset-xl-3">
              <div className="area-title text-center mb-60">
                <h2 className="mb-15">Pricing</h2>
                <p></p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-6">
              <div
                className="pricing-box text-center white-bg pb-50 mb-30 wow fadeIn"
                data-wow-delay=".2s"
              >
                <div className="price-head blue-gradient">
                  <h2>Starter</h2>
                </div>
                <div className="price-list">
                  <ul>
                    <li>Workout Weight/Rep Prediction ✅</li>
                    <li>Selection of 3 Workouts ✅</li>
                    <li>Unlimited Sessions ✅</li>
                    <li>Basic Statistics On Recent Sessions ✅</li>
                  </ul>
                </div>
                <div className="price-count mb-40">
                  <h2>
                    <span className="usd"></span>
                    <span></span>Free
                  </h2>
                  <span className="month"></span>
                </div>
                <div className="price-btn">
                  <a href="#" className="btn blue-gradient">
                    <i className="ti-apple"></i>
                    Download Now
                  </a>
                </div>
                <br />
                <div className="price-btn">
                  <a href="#" className="btn blue-gradient">
                    <i className="ti-android"></i>
                    Download Now
                  </a>
                </div>
              </div>
            </div> */}
            <div className="col-md-12">
              <div
                className="pricing-box text-center white-bg pb-50 mb-30 wow fadeIn"
                data-wow-delay=".4s"
              >
                <div className="price-head blue-gradient">
                  <h2>Premium ⭐️</h2>
                </div>
                <div className="price-list">
                  <ul>
                    <li>All Access To Workouts ✅</li>
                    <li>Unlimited Sessions ✅</li>
                    <li>Tailored Plans For Your Goal ✅</li>
                    <li>Optimal Weight Prediction ✅</li>
                    <li>Optimal Rep Prediction ✅</li>
                    <li>Comprehensive Stats On All Sessions ✅</li>
                    <li>Custom Workout Creator ✅</li>
                  </ul>
                </div>
                <div className="price-count mb-40">
                  <h2>
                    <span className="usd"></span>$4.99*<span></span>
                  </h2>
                  <span className="month">monthly</span>
                </div>
                <div className="price-btn">
                  <a
                    href="https://apps.apple.com/us/app/id1458287606"
                    className="btn blue-gradient"
                  >
                    <i className="ti-apple"></i>
                    Download Now
                  </a>
                </div>
                <br />
                <div className="price-btn">
                  <a href="https://play.google.com/store/apps/details?id=io.trackmylifts" className="btn blue-gradient">
                    <i className="ti-android"></i>
                    Download Now
                  </a>
                </div>
                <br />
                <br />
                <p>*Price may vary depending on your location</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Pricing
