import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import NavBar from "../components/NavBar"
import Slider from "../components/Slider"
import Features from "../components/Features"
import About from "../components/About"
import MoreFeatures from "../components/MoreFeatues"
import Pricing from "../components/Pricing"
import Team from "../components/Team"
import FAQ from "../components/FAQ"
import Download from "../components/Download"
import Footer from "../components/Footer"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title=" " isTemplate={false} />
        <Slider />
        <Features />
        <About />
        <MoreFeatures />
        <Pricing />
        <Team />
        <FAQ />
        <Download />
      </Layout>
    )
  }
}

export default IndexPage
