import React, { useState } from "react"

function FAQSingle(props) {
  const [open, setOpen] = useState(false)

  return (
    <div className="card">
      <div className="card-header" id="headingThree">
        <h5 className="mb-0">
          <button
            onClick={() => {
              console.log(open)
              setOpen(!open)
            }}
            className={
              open
                ? "btn-link collapsed faq-header-active"
                : "btn-link collapsed"
            }
            type="button"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            {props.question}
          </button>
        </h5>
      </div>
      <div
        id="collapseThree"
        className={open ? "collapse show" : "collapse"}
        aria-labelledby="headingThree"
        data-parent="#accordion"
      >
        <div className="card-body">{props.answer}</div>
      </div>
    </div>
  )
}

export default FAQSingle
