import React from "react"

class About extends React.Component {
  render() {
    return (
      <div
        id="about"
        className="about-app-area blue-gradient pt-70 pb-70 wow fadeInUp"
        data-wow-delay="0.3s"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="about-app">
                <h2 className="color-white">About trackmylifts</h2>
                <p>
                  trackmylifts is an intelligent mobile app for helping you
                  achieve your muscle building goals. Whether it’s increasing
                  strength, size, endurance or power, there’s a perfect workout
                  program waiting for you. Every session, trackmylifts will
                  guide you through the workout ensuring you make progress week
                  on week.
                </p>
                <p>
                  We've worked hard to create an algorithm to predict your
                  performance. Simply select a workout program and we'll tell
                  you the exercises, reps and the weight. Tailored to you.
                </p>
                <div className="get-app mt-30">
                  <a href="https://play.google.com/store/apps/details?id=io.trackmylifts">
                    <i className="ti-android"></i>
                    <p>
                      Download Now <br /> <b>Google Store</b>
                    </p>
                  </a>
                  <a href="https://apps.apple.com/us/app/id1458287606">
                    <i className="ti-apple"></i>
                    <p>
                      Download Now <br /> <b>Apple Store</b>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-app-thumb"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About
