import React from "react"
import muscle from "../img/muscle.svg"
import hourglass from "../img/hourglass.svg"
import growth from "../img/growth.svg"

class Features extends React.Component {
  render() {
    return (
      <div className="features-area pt-70 pb-70">
        <div className="container">
          <h3 className="text-center">Why use trackmylifts?</h3>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div
                className="features-wrapper text-center mb-30 wow fadeIn"
                data-wow-delay="0.2s"
              >
                <div className="features-icon-img mb-20">
                  <img src={muscle} alt="" />
                </div>
                <h4>Consistent Muscle Growth</h4>
                <p>
                  trackmylifts physically challenges you each week by increasing
                  the session intensity providing consistent results for you.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div
                className="features-wrapper text-center mb-30 wow fadeIn"
                data-wow-delay="0.4s"
              >
                <div className="features-icon-img mb-20">
                  <img src={hourglass} alt="" />
                </div>
                <h4>Saves Time</h4>
                <p>
                  Track your weight lifting performance on an easy to use app
                  interface so you can focus more time on what's important.
                  <br /> (Pumping iron)
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div
                className="features-wrapper text-center mb-30 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div className="features-icon-img mb-20">
                  <img src={growth} alt="" />
                </div>
                <h4>Keeps You Motivated</h4>
                <p>
                  We make it easy to see your progression at the gym to help you
                  stay motivated for your long term goal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Features
