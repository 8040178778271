import React from "react"

class Download extends React.Component {
  render() {
    return (
      <div className="download-area blue-gradient star-pattern pt-150 pb-150">
        <div
          className="smart-watch  wow fadeInRight"
          data-wow-delay=".3s"
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 offset-lg-2 offset-xl-3">
              <div className="area-title white-text text-center  mb-60">
                <h2 className="mb-15">Get trackmylifts Today</h2>
                <p></p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="get-app download-app text-center">
                <a href="https://play.google.com/store/apps/details?id=io.trackmylifts">
                  <i className="ti-android"></i>
                  <p>
                    Download Now
                    <br />
                    <b>Google Store</b>
                  </p>
                </a>
                <a href="https://apps.apple.com/us/app/id1458287606">
                  <i className="ti-apple"></i>
                  <p>
                    Download Now
                    <br />
                    <b>App Store</b>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Download
