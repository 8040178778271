import React from "react"
import face from "../img/team/face.jpg"

class Team extends React.Component {
  render() {
    return (
      <div className="team-area pos-relative team-shape blue-gradient pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 offset-lg-2 offset-xl-3">
              <div className="area-title white-text text-center mb-60">
                <h2 className="mb-15">Meet the creator</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                className="team-circle white-bg text-center mb-30 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="team-thumb mb-25">
                  <img src={face} alt="" />
                </div>
                <div className="team-content-circle">
                  <h5>Ben Wright</h5>
                  <span>Developer</span>
                  <div className="team-social mt-20">
                    <a href="https://benwrightdev.com/">
                      <i className="ti-world"></i>
                    </a>
                    <a href="https://twitter.com/benwrightdev">
                      <i className="ti-twitter-alt"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/ben-wright-8271439a/">
                      <i className="ti-linkedin"></i>
                    </a>
                    <a href="https://www.instagram.com/benwrightdev">
                      <i className="ti-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Team
