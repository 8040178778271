import React from "react"
import landing from "../img/bg/landing.jpeg"
import { Link } from "gatsby"
import screenshot from "../img/screenshot/bench-press-screen.png"

class Slider extends React.Component {
  render() {
    return (
      <div className="pos-relative">
        <div
          id="home"
          className="slider-area blue-gradient pos-relative wave-shape"
        >
          <div className="slider-active">
            <div className="single-slider slider-height d-flex align-items-top">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-8">
                    <div className="silder-content pt-180">
                      <h1
                        className="white-color mb-35"
                        data-animation="fadeInDown"
                        data-delay=".3s"
                      >
                        An <b>Intelligent</b> app to make building muscle
                        easier.
                      </h1>
                      <p>
                        Powered by{" "}
                        <Link to="/amp-technologies">AMP Technologies</Link>
                      </p>
                      <p>
                        Driven by science to create consistent muscle building
                        results.
                      </p>
                      <p data-animation="fadeInDown" data-delay=".5s"></p>
                      <div className="slider-btn mt-30">
                        <div className="get-app download-app ">
                          <a href="https://play.google.com/store/apps/details?id=io.trackmylifts">
                            <i className="ti-android"></i>
                            <p>
                              Download Now
                              <br />
                              <b>Google Store</b>
                            </p>
                          </a>
                          <a href="https://apps.apple.com/us/app/id1458287606">
                            <i className="ti-apple"></i>
                            <p>
                              Download Now
                              <br />
                              <b>App Store</b>
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <div
                      className="slider-app-thumb pt-180"
                      data-animation="fadeInRight"
                      data-delay="1.2s"
                    >
                      <img src={screenshot} alt="" style={{ width: "100%" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="land-bg" src={landing} />
      </div>
    )
  }
}

export default Slider
