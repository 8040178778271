import React from "react"
import FAQSingle from "./FAQSingle"
import { Link } from "gatsby"
import SEO from "../components/seo"

function FAQ() {
  return (
    <div id="faq" className="faq-area pt-70 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 offset-lg-2 offset-xl-3">
            <div className="area-title text-center mb-70">
              <h2 className="mb-15">Frequently Asked Questions (FAQs)</h2>
              <p></p>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div
            className="col-xl-12 col-lg-12"
            style={{
              margin: "auto",
            }}
          >
            <div
              className="faq-wrapper mb-30 wow fadeInLeft"
              data-wow-delay=".3s"
            >
              <FAQSingle
                question="How does trackmylifts predict weight lifting progress?"
                answer={
                  <div>
                    <p>
                      We have developed a weight lifting specific neural network
                      to predict performance using{" "}
                      <Link to="/amp-technologies">AMP Technologies</Link>.
                    </p>
                    <p>
                      Inside the app, after you complete each set, you will be
                      advised to enter the difficulty on a scale of 1-5. We use
                      this data alongside the number of reps achieved and the
                      weight used into our neural network. We then use this data
                      for future workouts to recommend the best reps and weight
                      to use to maximise gains.
                    </p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
